<script setup lang="ts">
definePageMeta({
  layout: 'primary',
  key: "1Home",
  pageTransition: {
    name: 'slide-right',
    mode: 'in-out'
  },
});

import {useCoreStore} from "~/stores/core";
import {useSearchStore} from "~/stores/search";

const search = useSearchStore();
const core = useCoreStore();


const link = computed(() => {
  if (search.check_in && search.check_out) {
    return `/search-city/odessa?check_in=${search.check_in}&check_out=${search.check_out}`
  }
  return '/search-city/odessa';
})

const {data, pending, error} = await useLazyAsyncData(
    'index', () => useApiFetch('index', {
      body: {'currency': core.currency}
    })
)

const meta = computed(() => {
  return data.value ? data.value.content : {};
})

useSeoMeta({
  title: () => meta.value.seo_title,
  ogTitle: () => meta.value.seo_title,
  description: () => meta.value.seo_description,
  ogDescription: () => meta.value.seo_description,
  ogImage: () => '/images/barkar-with-bg.jpg',
  twitterImage: () => '/images/barkar-with-bg.jpg',
})
</script>

<template>
  <div>
    <div class="w-full h-[calc(100lvh)] relative">
      <NuxtLazyHydrate whenIdle>
        <ElementsImage
            preload
            size="md"
            loading="eager"
            alt="Screen banner main page"
            src="/storage/acc/sm/25/56873/apartment-in-arcadia-with-large-terrace-546.webp"
            imageClass="w-full h-full top-0 left-0 absolute -z-10 object-cover"
        />
      </NuxtLazyHydrate>
      <div
          class=" h-full w-full top-0 left-0 absolute bg-gradient-to-r from-secondary from-10% via-secondary/90 via-50% to-secondary/80 to-90% -z-10 transition-all duration-1000"
      />
      <div class="container mx-auto h-full flex md:flex-center justify-center flex-col z-10 text-white">
        <div
            class="mb-20 flex flex-col  md:w-[976.7px] md:text-center transition-all duration-1000"
            key="text"
        >
          <span class="lg:text-2xl text-xl mb-5 uppercase font-light">{{ $t('text.Service Apartments') }}</span>
          <h1 class="font-bold uppercase text-white">
            <span class="block mb-2">{{ $t('text.Comfort Stay with') }}</span>
            <span class="md:tracking-wide highlighted text-primary">Barkar Apartments</span></h1>
        </div>
        <div
            class="xl:w-[976.7px] lg:w-[776.7px]"
        >
          <TheSearchPanel>
            <template #button>
              <div class="flex flex-center md:w-40 py-2 px-2">
                <NuxtLinkLocale class="flex flex-center h-14 w-full md:w-36 text-sm" :to="link">
                  <ElementsButton
                      class="hover:bg-secondary bg-secondary border-secondary text-white"
                  >
                    {{ $t('text.search') }}
                  </ElementsButton>
                </NuxtLinkLocale>
              </div>
            </template>
          </TheSearchPanel>
        </div>
      </div>
    </div>
    <div
        class="bg-gray relative overflow-hidden"
        v-if="data"
    >
      <NuxtLazyHydrate when-visible>
        <div class="absolute -top-32 -left-[700px] opacity-50">
          <svg width="1127" stroke="#00bfff" height="1084" viewBox="0 0 1127 1084" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M354.778 750.217C331.174 760.103 322.208 784.338 337.636 798.173C369.378 826.643 500.052 812.579 506.2 793.281C512.257 774.269 404.033 729.582 354.778 750.217Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M413.267 686.894C467.185 668.002 557.718 802.51 520.348 834.266C497.372 853.792 441.752 851.352 396.394 844.935C353.813 838.912 292.97 814.282 277.959 773.378C270.011 751.663 274.782 720.724 291.615 709.89C304.643 701.506 322.231 705.278 326.111 703.884C344.607 711.558 347.322 714.541 362.058 716.341C381.32 718.696 398.439 692.089 413.267 686.894Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M467.973 620.738C530.593 630.738 598.642 827.203 530.713 872.416C487.452 901.211 412.033 888.496 359.464 873.273C311.175 859.29 230.767 812.625 214.499 745.748C205.042 706.889 216.533 652.225 249.457 636.412C275.702 623.812 309.517 634.647 315.471 635.481C350.664 654.419 353.51 664.054 379.714 670.54C414.41 679.117 441.803 616.537 467.973 620.738Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M518.714 555.021C587.854 573.456 635.6 852.175 537.113 911C473.55 948.962 378.309 926.189 318.568 902.046C264.525 880.21 164.89 811.499 147.073 718.553C136.325 662.499 154.261 584.228 203.328 563.37C242.755 546.603 293.107 563.81 300.855 567.513C355.024 593.417 355.71 614 393.422 625.172C443.517 640.053 482.504 545.386 518.714 555.021Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M566.403 492.293C642.971 518.888 669.381 879.856 540.461 952.579C456.511 999.512 341.487 966.934 274.628 933.818C214.767 904.177 96.0298 813.425 76.5735 694.346C64.614 621.149 88.9158 519.242 154.13 493.304C206.762 472.389 273.25 496.921 283.176 502.516C353.001 541.877 354.807 567.021 404.016 582.799C469.522 603.983 520.571 476.378 566.403 492.293Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M102.789 424.073C168.604 399.015 251.189 430.839 263.354 438.359C348.647 491.093 351.801 520.688 412.49 541.22C493.418 568.599 556.832 407.73 611.91 430.359C696.512 465.12 701.26 908.8 541.627 994.952C437.466 1051.17 302.438 1008.51 228.498 966.379C162.833 928.967 25.0787 816.174 3.93673 670.94C-9.20554 580.605 21.416 455.051 102.789 424.073Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M814.964 378.357C789.039 378.14 757.949 333.172 781.199 325.664C794.627 321.321 830.18 328.189 844.145 340.413C863.813 357.613 832.803 378.511 814.964 378.357Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M744.51 331.961C747.047 325.926 758.926 323.246 769.766 315.897C791.753 300.983 794.907 293.897 810.524 284.902C816.615 281.388 824.809 276.77 832.808 278.885C845.059 282.125 848.116 296.148 863.664 320.846C883.823 352.939 891.457 361.236 885.698 378.243C884.355 382.197 882.618 395.003 872.91 402.815C859.464 413.615 843.705 405.272 824.003 412.421C800.341 420.993 788.611 436.251 778.342 429.507C771.108 424.759 770.84 411.753 765.423 389.106C761.297 371.854 758.703 360.533 753.686 351.299C747.669 340.304 741.687 338.67 744.51 331.961Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M704.883 344.539C705.323 333.841 722.122 328.664 735.967 313.206C764.36 281.496 751.206 258.838 773.965 235.672C783.622 225.837 798.776 216.311 813.964 218.157C835.517 220.78 842.757 243.049 875.938 288.199C919.319 347.23 937.798 358.79 933.747 390.249C932.918 396.661 932.113 421.279 915.159 436.096C892.549 455.857 865.447 437.879 830.049 452.731C790.496 469.303 775.714 504.733 762.377 497.402C753.115 492.31 756.663 471.806 749.544 431.816C744.504 403.392 741.39 386.78 731.316 373.174C718.602 356.013 704.409 356.156 704.883 344.539Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M665.216 356.979C663.502 341.647 685.255 333.938 702.129 310.371C736.87 261.855 706.917 223.934 737.367 186.298C750.401 170.195 772.754 155.788 795.079 157.291C826.02 159.371 837.454 189.715 888.217 255.415C954.957 341.258 983.699 356.373 981.813 402.118C981.442 411.038 981.613 447.405 957.426 469.258C925.644 497.962 887.143 470.315 836.117 492.916C780.634 517.488 762.852 573.073 746.407 565.176C735.121 559.712 742.35 531.734 733.659 474.401C727.659 434.805 724.042 412.981 708.934 394.923C689.461 371.654 667.05 373.494 665.216 356.979Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M625.458 369.385C621.612 349.436 648.257 339.167 668.199 307.509C709.214 242.157 662.399 189.121 700.683 136.896C717.048 114.553 746.658 95.2604 776.108 96.3919C816.461 97.9462 831.906 136.468 900.383 222.602C990.27 335.407 1029.39 354.036 1029.74 413.958C1029.81 425.387 1030.94 473.469 999.544 502.385C958.552 539.997 908.725 502.699 842.037 533.071C770.611 565.61 749.829 641.401 730.345 632.921C717.031 627.104 727.945 591.628 717.682 516.957C710.705 466.183 706.608 439.176 686.467 416.644C660.216 387.294 629.595 390.837 625.458 369.385Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M585.625 381.786C592.065 408.187 630.886 402.929 663.907 438.359C689.089 465.372 693.666 497.55 701.62 559.507C713.431 651.51 698.849 694.489 714.191 700.655C736.744 709.724 760.52 613.72 847.899 573.222C930.216 535.072 991.47 582.102 1041.61 535.506C1080.25 499.579 1078.11 439.782 1077.6 425.787C1074.94 351.704 1025.51 329.544 912.468 189.778C826.272 83.2085 806.833 36.521 757.046 35.4867C720.476 34.7267 683.592 58.9162 663.907 87.4887C617.812 154.348 681.581 222.528 634.194 304.64C611.23 344.43 579.631 357.225 585.625 381.786Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M915.068 806.716C908.302 803.287 894.097 809.893 892.84 820.185C891.417 831.86 907.491 841.517 914.553 838.517C922.907 834.963 924.044 811.253 915.068 806.716Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M934.13 868.747C945.661 857.964 950.701 838.175 956.466 804.253C962.18 770.618 961.283 746.537 956.26 744.788C953.523 743.845 945.769 751.223 939.118 756.629C926.667 766.72 905.354 765.6 899.891 766.692C879.892 770.692 874.018 778.121 865.658 792.35C861.79 798.922 852.768 813.494 848.796 830.637C842.74 856.598 815.592 864.598 818.901 875.004C821.683 883.69 848.579 885.799 866.019 886.045C891.012 886.399 916.302 885.399 934.13 868.747Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M954.117 897.737C973.071 878.467 980.528 846.129 992.013 785.652C1003.68 724.884 1003.61 683.203 997.858 681.648C993.544 680.46 979.573 695.483 967.7 706.221C945.318 726.547 905.251 723.204 896.994 724.507C859.339 730.325 850.059 744.262 835.466 770.606C829.386 781.578 812.707 808.322 805.107 839.906C793.41 888.291 737.956 900.96 742.544 918.898C746.378 933.835 797.804 936.127 830.163 934.898C875.852 933.121 922.09 930.309 954.117 897.737Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M973.996 926.538C1000.39 898.788 1010.18 853.878 1027.45 766.863C1044.98 678.946 1045.8 619.76 1039.34 618.286C1033.43 616.915 1013.25 639.544 996.19 655.642C963.934 686.254 905.062 680.626 894.006 682.1C838.705 689.483 825.969 710.13 805.181 748.645C796.884 764.012 772.56 802.933 761.32 848.941C744.001 919.755 660.233 937.081 666.09 962.556C670.981 983.746 746.949 986.312 794.216 983.506C860.59 979.763 927.844 975.065 973.996 926.538Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M993.813 955.322C1027.66 919.092 1039.77 861.604 1062.82 748.051C1086.25 632.995 1087.96 556.324 1080.77 554.901C1073.25 553.398 1046.86 583.542 1024.62 605.011C982.43 645.841 904.822 637.984 890.966 639.67C818.021 648.522 801.73 675.928 774.84 726.656C764.292 746.405 732.356 797.516 717.488 857.952C694.529 951.19 582.448 973.18 589.591 1006.19C595.528 1033.62 696.037 1036.36 758.217 1032.1C845.248 1026.23 933.558 1019.82 993.813 955.322Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M887.898 597.222C797.313 607.509 777.617 641.796 744.475 704.655C731.733 728.822 692.123 792.087 673.621 866.947C645.051 982.62 504.64 1009.27 513.057 1049.81C520.051 1083.53 645.097 1086.38 722.191 1080.67C829.912 1072.67 939.261 1064.57 1013.61 984.094C1054.91 939.378 1069.34 869.33 1098.17 729.228C1127.45 587.028 1130.06 492.887 1122.17 491.504C1113.03 489.904 1080.46 527.506 1053.03 554.364C1000.9 605.406 904.56 595.331 887.898 597.222Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M151.359 322.355C161.644 361.785 235.469 343.264 323.351 432.073C354.841 463.897 375.955 497.184 417.633 502.362C421.838 502.882 463.591 507.448 493.058 480.647C530.131 446.931 507.503 400.158 550.77 334.927C566.706 310.897 569.357 317.875 581.054 298.925C630.972 218.037 554.838 135.919 598.768 53.7731C618.989 15.9603 649.051 7.27992 646.194 2.34259C633.863 -18.8411 123.566 216.105 151.359 322.355Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M429.061 448.457C431.586 448.114 459.117 450.171 478.001 431.222C501.88 407.295 484.858 374.351 512.217 329.875C522.285 313.497 523.685 317.938 530.851 304.983C561.421 249.701 506.554 194.087 533.422 139.971C545.856 114.918 565.483 109.175 562.563 106.729C549.358 95.5747 212.533 260.364 238.989 332.069C248.909 358.962 303.061 349.419 365.024 407.341C387.554 428.387 402.81 452.017 429.061 448.457Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M440.489 394.546C441.209 392.735 454.671 392.775 462.945 381.797C473.687 367.557 462.248 348.584 473.664 324.829C477.853 316.109 478.024 317.972 480.653 311.04C491.938 281.228 458.225 252.289 468.082 226.163C472.693 213.865 481.264 211.768 478.979 211.111C462.271 213.482 301.844 304.783 326.591 341.784C336.253 356.242 370.84 355.55 406.667 382.609C420.284 392.895 429.198 400.426 440.489 394.546Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
            <path
                d="M451.917 340.641C443.049 314.726 408.136 305.914 395.348 315.497C376.492 329.647 392.577 348.07 414.204 351.499C430.369 354.042 462.065 370.3 451.917 340.641Z"
                stroke-width="0.5" stroke-miterlimit="10"></path>
          </svg>
        </div>
      </NuxtLazyHydrate>
      <section class="section">

        <TemplatesBigInfoBlock
            items-two-line
            :items="data.whyUs"
            :subtitle="$t('text.index.subtitle')"
        >
          <template #image>
            <div class="w-full h-full top-0 left-0 absolute">
              <ElementsImage
                  size="md"
                  alt="Why barkar apartments 1"
                  src="/storage/acc/max/22/f3ec7/rent-short-period-flat-in-arkadia-2-bedrooms-510.webp"
                  image-class="w-full h-full object-cover -z-10"
              />
            </div>
          </template>
          <template #title>
            <h2 class="mb-4">
              <span class="mb-2 block font-light">{{ $t('text.index.slogan 1') }}</span>
              <span class="font-bold highlighted"><span class="text-primary">Barkar</span> Apartments</span>
            </h2>
          </template>
        </TemplatesBigInfoBlock>
      </section>

      <section class="mx-auto container section" v-if="data">
        <h2 class="mb-4">
          <span class="mb-2 block font-light">{{ $t('text.index.slogan 2') }}</span>
          <span class="font-bold highlighted"><span>{{ $t('text.index.slogan 21') }} </span> <span
              class="text-primary">{{ $t('text.index.slogan 22') }}</span></span>
        </h2>
        <span class="lg:text-xl text-primary-text">
            {{ data.mission.subtitle }}
          </span>
        <div class="grid md:grid-cols-2 gap-5 min-h-[250px] pt-10">
          <div
              v-if="data"
              v-for="item in data.mission.preferences"
              class="bg-white w-full h-full p-5 rounded-d flex justify-start flex-col"
          >
            <h3 class="text-primary mb-5 font-bold">{{ item.title }}</h3>
            <span>{{ item.text }}</span>
          </div>
        </div>
      </section>
      <section class="section">
        <TemplatesSmallInfoBlock
            class="mb-5"
            items-two-line
            :subtitle="$t('text.index.text')"
        >
          <template #image>
            <div class="w-full h-full top-0 left-0 absolute">
              <ElementsImage
                  size="sm"
                  alt="Why barkar apartments 4"
                  src="storage/acc/max/16/ef6d1/rent-apartment-in-arkadia-cheap-for-holiday-odessa-732.webp"
                  image-class="w-full h-full object-cover -z-10"
              />
            </div>
          </template>
          <template #title>
            <h2 class="mb-4">
              <span class="mb-2 block font-light">{{ $t('text.index.info 1') }}</span>
              <span class="font-bold text-primary">{{ $t('text.index.info 2') }} </span>
            </h2>
          </template>
        </TemplatesSmallInfoBlock>
        <div class="mx-auto container">
          <div class="grid md:grid-cols-2 gap-5">
            <div class="bg-white w-full h-full p-5 rounded-d flex flex-col lg:flex-row justify-between items-center">
              <img
                  class="w-[100px] h-[39px] mb-2 lg:mb-0"
                  src="/images/bnbsuperhost.png" alt="bnbsuperhost"
                  loading="lazy"
              >
              <div class="text-control flex flex-wrap items-center justify-center">
                <Icon v-for="i in 5" name="mingcute:star-fill" class="w-6 h-6 block text-alert"/>
                <p class="lg:pl-1 lg:pt-0 pt-3 w-full text-center"><strong>4.83 {{ $t('text.of') }} 5</strong>
                  {{ $t('text.based on') }} 800+ {{ $t('text.reviews') }}</p>
              </div>
            </div>
            <div class="bg-white w-full h-full p-5 rounded-d flex flex-col lg:flex-row justify-between items-center">
              <img
                  class="w-[100px] h-[58px] mb-2 md:mb-0"
                  loading="lazy"
                  src="/images/booking-logo.png"
                  alt="booking small logo">
              <div class="text-control flex flex-wrap items-center justify-center">
                <Icon v-for="i in 5" name="mingcute:star-fill" class="w-6 h-6 block text-alert"/>
                <p class="lg:pl-1 lg:pt-0 pt-3 w-full text-center"><strong>9.22 {{ $t('text.of') }} 10</strong>
                  {{ $t('text.based on') }} 500+ {{ $t('text.reviews') }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mx-auto container text-secondary section">
        <h2 class="font-bold mb-5 flex flex-wrap w-full justify-center">
          <span class="first-letter:text-primary mr-2">{{ $t('text.Frequently') }} </span>
          <span class="first-letter:text-primary mr-2"> {{ $t('text.Asked') }}</span>
          <span class="first-letter:text-primary">{{ $t('text.Questions') }}</span>
        </h2>
        <span class="w-full block text-center text-control">{{ $t('text.Find answers') }}</span>
        <div class="pt-20" v-if="data">
          <ElementsExpander
              class="mb-10"
              v-for="item in data.faq"
              :title="item.title">
            {{ item.text }}
          </ElementsExpander>
        </div>
      </section>
      <section class="mx-auto container flex flex-wrap section" v-if="data.buildings">
        <ElementsPlaceLink
            v-for="(category) in [...data.buildings, ...data.areas, ...data.accommodation_account]"
            :category="category"
        />
      </section>
    </div>
  </div>
</template>